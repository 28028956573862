import { Injectable } from '@angular/core';
import { MnRegistryService } from '@modalnodes/mn-registry';

@Injectable({
  providedIn: 'root'
})
export class MnConfigurationRegsitryService extends MnRegistryService<string> {
  get keys() {
    return Object.keys(this._registry);
  }
}
