import { Inject, forwardRef } from '@angular/core';
import { MnRegistryCollectionService } from './mn-registry-collection.service';
import { MnRegistryInterface, MnRegistryCollectionInterface } from './mn-registry';

export class MnMetaRegistryService implements MnRegistryCollectionInterface<MnRegistryInterface<any>> {
  _name_registry = {};
  _tags_registry = {};

  _registries = {};
  _default = null;

  constructor() {
    this.setDefault(this);
  }

  registerCollection(name: string) {
    throw new Error('Cannot register meta-registry to meta-registry');
  }
  setDefault(item: MnRegistryInterface<any>) {
    this._default = item;

  }
  register(name: string, item: MnRegistryInterface<any>) {
    this._registries[name] = item;
  }
  for(name: string): MnRegistryInterface<any> {
    if (this._registries.hasOwnProperty(name)) {
      return this._registries[name];
    } else {
        return this._default;
      }
  }

  registerName(name: string, item: MnRegistryInterface<any>) {
    this._name_registry[name] = item;
    this.register(name, item);
  }

  registerTags(tags: string[], item: MnRegistryInterface<any>) {
    for (const tag of tags) {
      if (!this._tags_registry.hasOwnProperty(tag)) {
        this._tags_registry[tag] = [];
      }
      this._tags_registry[tag].push(item);
    }
  }
}
