import { MnConfiguratorService, fileLoader } from './mn-configurator.service';
import { MnConfigurationRegsitryService } from './mn-configuration-regsitry.service';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MnRegistryModule } from '@modalnodes/mn-registry';

@NgModule({
  imports: [
    MnRegistryModule
  ],
  declarations: [],
  exports: [],
  providers: [
    MnConfigurationRegsitryService,
    MnConfiguratorService,
    {'provide': APP_INITIALIZER, 'useFactory': fileLoader, 'deps': [MnConfiguratorService], 'multi': true},
  ]
})
export class MnConfiguratorModule { }
