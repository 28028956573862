import { MnMetaRegistryService } from '../mn-meta-registry.service';
import {
  Component, Input, ViewChild, ViewContainerRef, Inject, Injector, ComponentFactoryResolver, ComponentFactory, ComponentRef,
  OnInit, OnDestroy, TemplateRef, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Injectable, Type } from '@angular/core';


@Component({
  selector: 'mn-registrycomponent, [mnRegistryComponent]',
  template: '<ng-template #componentContainer></ng-template>'
})
export class MnRegistryComponent implements OnInit, OnDestroy {

  @ViewChild('componentContainer', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>;

  @Input() registry: string;
  @Input() component: string;
  @Input() data: any;

  constructor(
    private lreg: MnMetaRegistryService,
    private resolver: ComponentFactoryResolver,
    private hostElement: ElementRef,
    private injector: Injector,
  ) { }

  ngOnInit() {
    const reg = this.lreg.for(this.registry);
    this.createComponent(reg.for(this.component));
  }

  createComponent(comp) {
    console.log('creating component', comp);
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(comp);
    // const ngContent = this.resolveNgContent(this.hostElement);
    // const componentRef = factory.create(this._injector, ngContent);
    // componentRef.hostView.detectChanges();
    // const { nativeElement } = componentRef.location;
    // document.body.appendChild(nativeElement);
    this.container.clear();
    let cc: any = null;
    if (this.data.hasOwnProperty('addables')) {
      cc = this.container.createComponent(factory, 0, undefined, this.resolveNgContents(this.data.addables));
    } else {
      cc = this.container.createComponent(factory);
    }
    if (this.data) {
      cc.instance.data = this.data;
    }
    this.componentRef = cc;
  }

  ngOnDestroy() {
    this.componentRef.destroy();
   }


  resolveNgContents(content: any[][]) {
    const c = [];
    content.forEach((v, i) => {
      c.push([]);
      v.forEach((co, j) => {
        console.log(co);
        c[i].push(this.resolveNgContent(co));
      });
    });
    console.log('>>', c);
    return [c];
  }

  resolveNgContent(content: any) {
    console.log('resolving content', content);
    if (typeof content === 'string') {
      const element = document.createTextNode(content);
      return element;
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null);
      return viewRef.rootNodes;
    }

    /** Otherwise it's a component */
    // const factory = this.resolver.resolveComponentFactory(this.lreg.for(this.registry).for(content.constructor.name));
    // const componentRef = factory.create(this.injector);
    // console.log('natvie', componentRef.location.nativeElement);
    // return componentRef.location.nativeElement;

    const factory = this.resolver.resolveComponentFactory(content);
    const componentRef = factory.create(this.injector);
    return componentRef.location.nativeElement;
  }
}
