import { NgModule } from '@angular/core';
import { MnMetaRegistryService } from './mn-meta-registry.service';
import { MnRegistryComponent } from './mn-registry/mn-registry.component';


@NgModule({
  imports: [
  ],
  declarations: [MnRegistryComponent],
  exports: [MnRegistryComponent],
  providers: [MnMetaRegistryService]
})
export class MnRegistryModule {
  constructor(
    private reg: MnMetaRegistryService
  ) {
    this.reg.register('registry', this.reg);
  }
 }
