import { MnConfigurationRegsitryService } from './mn-configuration-regsitry.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export function fileLoader(conf: MnConfiguratorService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      conf.loadFiles().then(data => {
        resolve();
      });
    });
  };
}

@Injectable({
  providedIn: 'root'
})
export class MnConfiguratorService {

  private _configs: Map<string, any> = new Map<string, any>();

  constructor(
    private conf: MnConfigurationRegsitryService,
    private http: HttpClient
  ) { }

  public loadFiles(): Promise<any> {
    const list = [];
    for (const k of this.conf.keys) {
      list.push(this.conf.for(k));
    }
    return this.getFiles(list);
  }

  public getFiles(list: string[]): Promise<any> {
    const proms = [];
    for (const f of list) {
      proms.push(
        new Promise((resolve, reject) => {
          this.http.get(f).toPromise().then(data => {
            this._configs[f] = data;
            resolve();
        });
      }));
    }
    return Promise.all(proms);
  }

  public getConfiguration(key: string, file: string) {
    return this._configs[file][key];
  }

  public getKeys(file: string) {
    return Object.keys(this._configs[file]);
  }
}
