import { MnConfiguratorModule, MnConfigurationRegsitryService } from '@modalnodes/mn-configurator';
import { NgModule } from '@angular/core';
import { MnDockerService } from './mn-docker.service';

@NgModule({
  imports: [
    MnConfiguratorModule
  ],
  declarations: [],
  exports: [],
  providers: [
    MnDockerService,
  ]
})
export class MnDockerModule {
  constructor(
    private conf: MnConfigurationRegsitryService
  ) {
    this.conf.register('mn-docker', '/assets/env.json');
  }
}
