import { MnMetaRegistryService } from './mn-meta-registry.service';
import { MnRegistryInterface } from './mn-registry';

export class MnRegistryService<T> implements MnRegistryInterface<T> {
  private _registry: any = {};

  private _default: T = undefined;

  constructor() { }

  public for(name: string): T {
    if (this._registry.hasOwnProperty(name)) {
      return this._registry[name];
    } else {
      return this._default;
    }
  }

  public register(name: string, item: T): void {
      this._registry[name] = item;
  }

  public setDefault(item: T): void {
      this._default = item;
  }
}
