import { Injectable } from '@angular/core';
import { MnConfiguratorService } from '@modalnodes/mn-configurator';

export const DOCKER_ENV_FILE = '/assets/env.json';

@Injectable({
  providedIn: 'root'
})
export class MnDockerService {

  constructor(
    private conf: MnConfiguratorService
  ) { }

  getEnv(key: string) {
    return this.conf.getConfiguration(key, DOCKER_ENV_FILE);
  }
}
